body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body::-webkit-scrollbar {
  width: 1em;
}

body::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px #2c2f33; */
  background-color: #1e2020;
}

body::-webkit-scrollbar-thumb {
  background-color: #3f3f3f;
  /* outline: 1px solid slategrey; */
  /* border-radius: 50px; */
}
